import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RecycleSVG from "jsx:../../../assets/Recycle.svg";
import { useAuth } from "../../../hooks/useAuth";
import { TransactionTypeList } from "../../../constants/transactionTypeList";

const TransactionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AmountContent = styled.div`
  text-align: left;
`;

const DateContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 23px;
  text-align: left;
`;

const DateContentColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const GelAmount = styled.h2`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: var(--color-Black);
`;

const OriginalAmount = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-Gray);
`;

const DateText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-DarkGray);
`;

const DeleteButton = styled.button`
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  background: none;
  border: none;
  height: 18px;
  margin-bottom: 2px;
`;

const RecycleIcon = styled(RecycleSVG)`
  fill: var(--color-Gray);
  transition-duration: 0.1s;

  :hover {
    fill: var(--color-Black);
  }
`;

const TransactionType = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-Gray);
  text-align: left;
`;

interface Props {
  date: string;
  type: string
  amount: string;
  gelAmount: number;
  id: number;
  onClick: (id: number) => void;
}

function Transaction({ date, type, amount, gelAmount, id, onClick }: Props) {
  const [canDelete, setCanDelete] = useState(false);
  const { profile } = useAuth();

  useEffect(() => {
    if (profile && profile.premium.active) {
      setCanDelete(true);
    }
  }, [profile]);

  return (
    <TransactionContainer>
      <AmountContent>
        <GelAmount>{gelAmount} ლ</GelAmount>
        <OriginalAmount>{amount}</OriginalAmount>
      </AmountContent>
      <DateContentColumn>
        <DateContent>
          <DateText>{date}</DateText>
          {canDelete && (
            <DeleteButton>
              <RecycleIcon onClick={() => onClick(id)} />
            </DeleteButton>
          )}
        </DateContent>
        <TransactionType>{TransactionTypeList.find((t) => t.value === type)?.label ?? "Without Type"}</TransactionType>
      </DateContentColumn>
    </TransactionContainer>
  );
}

export default Transaction;
