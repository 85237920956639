import React, { useState } from "react";
import styled from "styled-components";
import Transaction from "./Transaction";
import Button from "../../shared/buttons/Button";
import { useDashboard } from "../../../hooks/useDashboard";
import { useAuth } from "../../../hooks/useAuth";
import { Months } from "../../../constants/month";
import axiosInstance from "../../../helpers/axiosInstance";
import { NumberFormatValues, NumericFormat } from "react-number-format";

import CopySVG from "jsx:../../../assets/Copy.svg";
import toast from "react-hot-toast";
import Card from "../../shared/Card";
import TransactionInput from "../../shared/inputs/TransactionInput";

const Title = styled.h2`
  padding-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const TransactionCardContainer = styled.div`
  padding: 8px;
`;

const TransactionsContainer = styled.div<StyleProps>`
  display: ${(props) => props.display};
  flex-direction: column;
  margin-bottom: 30px;
  row-gap: 18px;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 12px;
  border-top: 1px solid var(--color-Black);
`;
const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const TextGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const DeclarationFieldNumber = styled.div`
  font-size: 10px;
  color: var(--color-DarkGray);
`;

const Total = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  display: flex;
  align-items: center;
`;

const CopyButton = styled.button`
  display: flex;
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
  margin-left: 8px;
`;

const TotalAmount = styled.span`
`;

const CopyIcon = styled(CopySVG)`
  fill: var(--color-Gray);
  transition-duration: 0.1s;

  :hover {
    fill: var(--color-Black);
  }
`;

type StyleProps = {
  display: string;
};

interface Props {
  month: Month;
  index: number;
}

function TransactionCard({ month, index }: Props) {
  const { token } = useAuth();
  const [show, setShow] = useState(index == 0);
  const { setLastUpdateTime } = useDashboard();

  const [clickOnSubmit, setClickOnSubmit] = useState(false);

  const [codeValue, setCodeValue] = useState<number>();

  const onChangeAmount = (e: NumberFormatValues) => {
    let num = Number(e.floatValue);
    setCodeValue(num);
  };

  const [declarationButton, setDeclarationButton] = useState({
    text: "Submit Declaration",
    class: "",
    disabled: false,
    display: "inline-block",
  });

  const defaultDeclarationButton = () => {
    setDeclarationButton({
      text: "Submit Declaration",
      class: "",
      disabled: false,
      display: "inline-block",
    });
  };

  const submitDeclaration = () => {
    setClickOnSubmit(true);
    axiosInstance.post("/rsge/monthly_declarations");
    setDeclarationButton({ ...declarationButton, text: "Send Code" });
  };

  const sendDeclaration = () => {
    setClickOnSubmit(false);
    setCodeValue(undefined);

    if (codeValue) {
      axiosInstance.post("/rsge/requests/${id}/submit_code", { code: codeValue })
    };

    setDeclarationButton({
      text: "Submitting...",
      class: "submitting",
      disabled: true,
      display: "inline-block",
    });

    const interval = setInterval(() => {
      axiosInstance.get("/rsge/monthly_declarations/submitting_status").then((res) => {
        if (res.data.status == "succeeded") {
          setDeclarationButton({
            text: "Done",
            class: "submit",
            disabled: true,
            display: "inline-block",
          });
          setTimeout(() => {
            defaultDeclarationButton();
          }, 2000);

          clearInterval(interval);
        }

        if (res.data.status == "failed") {
          setDeclarationButton({
            text: "Failed",
            class: "failed",
            disabled: true,
            display: "inline-block",
          });
          setTimeout(() => {
            defaultDeclarationButton();
          }, 2000);

          clearInterval(interval);
        }
      });
    }, 4000);
  };

  const cancelSend = () => {
    setClickOnSubmit(false);
    setCodeValue(undefined);
    defaultDeclarationButton();
  };

  const toggleShowCard = () => {
    if (show == false) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const deleteTransaction = (id: number) => {
    if (confirm("Delete transaction?")) {
      if (token) {
        axiosInstance.delete("/transactions/" + id.toString()).then(() => {
          setLastUpdateTime(Date.now());
        });
      }
    }
  };

  const copyToClipboard = (value: number) => {
    navigator.clipboard.writeText(value.toString());
    toast.success("Copied!");
  }

  return (
    <Card>
      <TransactionCardContainer>
        <Title onClick={() => toggleShowCard()}>
          {Months[month.month as keyof typeof Months]}
        </Title>
        <TransactionsContainer display={show ? "flex" : "none"}>
          {month.transactions.map((transaction) => {
            return (
              <Transaction
                key={transaction.id}
                date={transaction.date}
                type={transaction.type ?? "without type"}
                amount={transaction.amount}
                gelAmount={transaction.gelAmount}
                id={transaction.id}
                onClick={deleteTransaction}
              />
            );
          })}
        </TransactionsContainer>
        <TotalContainer>
          <TotalRow>
            <TextGroup>
              <Text>Total Year</Text>
              <DeclarationFieldNumber>(15)</DeclarationFieldNumber>
            </TextGroup>
            <Total>
              <TotalAmount>{month.totals.yearGelAmount} ლ</TotalAmount>
              <CopyButton
                onClick={() => copyToClipboard(month.totals.yearGelAmount)}
              >
                <CopyIcon></CopyIcon>
              </CopyButton>
            </Total>
          </TotalRow>
          <TotalRow>
            <TextGroup>
              <Text>Total Month</Text>
              <DeclarationFieldNumber>(17)</DeclarationFieldNumber>
            </TextGroup>
            <Total>
              <TotalAmount>{month.totals.monthGelAmount} ლ</TotalAmount>
              <CopyButton
                onClick={() => copyToClipboard(month.totals.monthGelAmount)}
              >
                <CopyIcon></CopyIcon>
              </CopyButton>
            </Total>
          </TotalRow>
          <TotalRow>
            <TextGroup>
              <Text>Total Cash Month</Text>
              <DeclarationFieldNumber>(18)</DeclarationFieldNumber>
            </TextGroup>
            <Total>
              <TotalAmount>{month.totals.cashMonthGelAmount} ლ</TotalAmount>
              <CopyButton
                onClick={() => copyToClipboard(month.totals.cashMonthGelAmount)}
              >
                <CopyIcon></CopyIcon>
              </CopyButton>
            </Total>
          </TotalRow>
          <TotalRow>
            <TextGroup>
              <Text>Total POS Month</Text>
              <DeclarationFieldNumber>(19)</DeclarationFieldNumber>
            </TextGroup>
            <Total>
              <TotalAmount>{month.totals.posMonthGelAmount} ლ</TotalAmount>
              <CopyButton
                onClick={() => copyToClipboard(month.totals.posMonthGelAmount)}
              >
                <CopyIcon></CopyIcon>
              </CopyButton>
            </Total>
          </TotalRow>
          <TotalRow>
            <TextGroup>
              <Text>Total Bank Transfers Month</Text>
              <DeclarationFieldNumber>(20)</DeclarationFieldNumber>
            </TextGroup>
            <Total>
              <TotalAmount>{month.totals.transferMonthGelAmount} ლ</TotalAmount>
              <CopyButton
                onClick={() =>
                  copyToClipboard(month.totals.transferMonthGelAmount)
                }
              >
                <CopyIcon></CopyIcon>
              </CopyButton>
            </Total>
          </TotalRow>
          {month.totals.withoutTypeMonthGelAmount != 0 && (
            <TotalRow>
              <TextGroup>
                <Text>Total Without Type Month</Text>
                <DeclarationFieldNumber>(old format)</DeclarationFieldNumber>
              </TextGroup>
              <Total>
                <TotalAmount>
                  {month.totals.withoutTypeMonthGelAmount} ლ
                </TotalAmount>
                <CopyButton
                  onClick={() =>
                    copyToClipboard(month.totals.withoutTypeMonthGelAmount)
                  }
                >
                  <CopyIcon></CopyIcon>
                </CopyButton>
              </Total>
            </TotalRow>
          )}
          {/* {clickOnSubmit ? (
            <>
              <NumericFormat
                customInput={TransactionInput}
                value={codeValue}
                decimalScale={0}
                placeholder="Write code from SMS"
                onValueChange={(e) => onChangeAmount(e)}
              />

              <Button
                display={show ? declarationButton.display : "none"}
                text={declarationButton.text}
                onClick={sendDeclaration}
              />
            </>
          ) : (
            <>
              <Button
                display={
                  show && month.actions.submitDeclaration
                    ? declarationButton.display
                    : "none"
                }
                text={declarationButton.text}
                onClick={submitDeclaration}
                disabled={declarationButton.disabled}
                className={declarationButton.class}
              />
            </>
          )} */}
        </TotalContainer>
      </TransactionCardContainer>
    </Card>
  );
}

export default TransactionCard;
