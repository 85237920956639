import React from "react";
import styled, { keyframes } from "styled-components";

const submittingAnimations = keyframes`
  0% { left: -100%; }
  10% { left: -97%; }
  20% { left: -92%; }
  30% { left: -82%; }
  40% { left: -62%; }
  60% { left: -38%; }
  100% { left: -20%; }
`;

const submitAnimations = keyframes`
  0%{ left: -20%; }
  100%{ left: 0; }
`;

const StyledButton = styled.button<StyledProps>`
  display: ${(props) => props.display || "inline-block"};
  position: relative;
  align-self: center;
  width: 100%;
  padding: 10px 0;

  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-White);
  background-color: var(--color-Black);
  -webkit-tap-highlight-color: transparent;
  transition-property: background-color;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  overflow: hidden;

  &.danger {
    background-color: var(--color-Red);
  }

  &:active {
    margin-top: 4px;
    margin-bottom: -4px;
  }
  &.saved {
    background-color: var(--color-Green);
    &:active {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &.cancel {
    background-color: var(--color-Red);
  }
  &.failed {
    background-color: var(--color-Red);
  }
  &.submitting {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -20%;
      height: 100%;
      width: 100%;
      background: var(--color-Green);
      animation: ${submittingAnimations} 3s ease-out;
    }
    &:active {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.submit {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0%;
      height: 100%;
      width: 100%;
      background: var(--color-Green);
      animation: ${submitAnimations} 0.2s ease-in;
    }
    &:active {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

type StyledProps = {
  display?: string;
};

interface Props {
  onClick: () => void;
  text: string;
  display?: string;
  className?: string;
  disabled?: boolean;
}

function Button({ onClick, text, display, className, disabled }: Props) {
  return (
    <StyledButton
      className={className}
      display={display}
      disabled={disabled}
      onClick={() => onClick()}
    >
      {text}
    </StyledButton>
  );
}

export default Button;
