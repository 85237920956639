import React from "react";
import styled from "styled-components";

const DropdownContentItem = styled.option`
  border-radius: 10px;
  cursor: pointer;
`;

const DropdownSelect = styled.select`
  text-align: left;
  border: 1px solid var(--color-VeryLightGray);
  border-radius: 5px;
  padding: 10px 12px;
  color: var(--color-Black);
  outline: none;
  height: 42px;
  width: 100%;
  appearance: none;
  background: none;
  &:focus {
    border: 1px solid var(--color-Black);
  }
`;

interface Props {
  value: string;
  items: { key: string; name: string }[];
  onChange: (e: string) => void;
}

function TransactionDropdown({ value, items, onChange }: Props) {
  return (
    <div>
      <DropdownSelect onChange={(e) => onChange(e.target.value)} value={value}>
        {items.map((item) => {
          return (
            <DropdownContentItem key={item.key} value={item.key}>
              {item.name}
            </DropdownContentItem>
          );
        })}
      </DropdownSelect>
    </div>
  );
}

export default TransactionDropdown;
